<template>
  <div class="container">
    <div class="top">
      <el-select v-model="searchData.deviceTypeId" :placeholder="fun.getLanguageText($store.state.language, '请选择设备类型')"
                 @change="changeSearchData($event,1)">
        <el-option v-for="item in DeviceTypeList1" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
      <el-select v-model="searchData.deviceNameId" :placeholder="fun.getLanguageText($store.state.language, '请选择设备名称')"
                 @change="changeSearchData($event,2)">
        <el-option v-for="item in DeviceTypeList2" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
      <el-select v-model="searchData.deviceModelId" :placeholder="fun.getLanguageText($store.state.language, '请选择设备型号')"
                 @change="changeSearchData($event,3)">
        <el-option v-for="item in DeviceTypeList3" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
      <el-select v-model="formData.sn" :placeholder="fun.getLanguageText($store.state.language, '请选择设备型号')"
                 @change="changeSn">
        <el-option v-for="item in snList" :key="item.sn" :label="item.sn" :value="item.sn">
        </el-option>
      </el-select>
      <el-date-picker v-model="time" :picker-options="pickerOptions" align="right" class="time"
                      :end-placeholder="fun.getLanguageText($store.state.language, '结束日期')"
                      range-separator="~"
                      :start-placeholder="fun.getLanguageText($store.state.language, '开始日期')"
                      type="daterange"
                      unlink-panels @change="change">
      </el-date-picker>
    </div>
    <div class="top">
      <span style="margin-left: 20px">{{ fun.getLanguageText($store.state.language, '请选择时间单位') }}</span>
      <el-select v-model="formData.timeType" :placeholder="fun.getLanguageText($store.state.language, '请选择时间单位')"
                 @change="getDevicePrice">
        <el-option v-for="item in timeTypeList" :key="item.id"
                   :label="fun.getLanguageText($store.state.language, item.name)" :value="item.id">
        </el-option>
      </el-select>
    </div>
    <div id="myChart" :style="{width: '1200px', height: '500px'}"></div>
  </div>
</template>
<script>
// 引入基本模板
let echarts = require('echarts/lib/echarts')
// 引入柱状图组件
require('echarts/lib/chart/bar')
// 引入提示框和title组件
require('echarts/lib/component/tooltip')
require('echarts/lib/component/title')
var posList = [
  'left', 'right', 'top', 'bottom',
  'inside',
  'insideTop', 'insideLeft', 'insideRight', 'insideBottom',
  'insideTopLeft', 'insideTopRight', 'insideBottomLeft', 'insideBottomRight'
];

var labelOption = {
  show: false,
  distance: {
    min: 0,
    max: 100
  },
  align: {
    options: {
      left: 'left',
      center: 'center',
      right: 'right'
    }
  },
  verticalAlign: {
    options: {
      top: 'top',
      middle: 'middle',
      bottom: 'bottom'
    }
  },
  rotate: {
    min: -90,
    max: 90
  },
  formatter: '{c}  {name|{a}}',
  fontSize: 16,
  rich: {
    name: {
      textBorderColor: '#fff'
    }
  }
};
export default {
  data() {
    return {
      pickerOptions: { // 限制收货时间不让选择今天以前的
        disabledDate(time) {
          return time.getTime() > Date.now() - 24 * 60 * 60 * 1000
        }
      },
      chart: '',
      time: '',
      formData: {
        sn: '',
        start: '',
        end: '',
        timeType: 'day'
      },
      // 搜索处理*********
      searchData: {
        deviceTypeId: '',
        deviceNameId: '',
        deviceModelId: '',
      },
      DeviceTypeList1: [],
      DeviceType1: 0,
      DeviceTypeList2: [],
      DeviceType2: 0,
      DeviceTypeList3: [],
      DeviceType3: 0,
      snList: [],//设备sn码列表
      list: [], // 统计表数据
      timeTypeList: [
        {
          name: '年',
          id: 'year'
        },
        {
          name: '月',
          id: 'month'
        }, {
          name: '日',
          id: 'day'
        }
      ],
      nameList: [],
    }
  },
  created() {
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 10);
    this.time = [start, end]
    this.formData.start = parseInt(start.getTime() / 1000)
    this.formData.end = parseInt(end.getTime() / 1000)
    this.getDeviceTypeList(1)
    // this.getDevicePrice()
  },
  methods: {
    // 设备统计
    async statisticsMap() {
      console.log(this.formData)
      return
      let res = await this.$http.post('/v1/statistics/map', {
        sn: ""
      })
    },
    // 当前设备被改变
    changeSn() {
      // 更新设备统计
      this.getDevicePrice()
    },
    // 时间被改变
    change(e) {
      this.formData.start = parseInt((e[0]).valueOf() / 1000)
      this.formData.end = parseInt((e[1]).valueOf() / 1000)
      this.getDevicePrice()
    },
    // 获取下拉列表----sn
    dropdown() {
      this.$http.post('device/dropdown-list', this.searchData)
          .then(res => {
            console.log(res)
            if (res.data.code === 2000) {
              this.snList = res.data.data
            }
          })
    },
    // 下拉选择框被改变
    changeSearchData(e, id) {
      this.formData.sn = ''
      console.log(e, id)
      if (id === 1) {
        this.DeviceType1 = e
        this.searchData.deviceNameId = ''
        this.searchData.deviceModelId = ''
        this.DeviceTypeList2 = []
        this.DeviceTypeList3 = []
        this.getDeviceTypeList(2, e)
      }
      if (id === 2) {
        this.DeviceType2 = e
        this.searchData.deviceModelId = ''
        this.DeviceTypeList3 = []
        this.getDeviceTypeList(3, e)
      }
      if (id === 3) {
        this.DeviceType3 = e
      }
      this.dropdown()
    },
    // 化料列表
    getDevicePrice() {
      this.$http.post('device/get-price', {
        sn: this.formData.sn,
        read: true
      }).then(res => {
        if (res.data.code === 2000) {
          this.nameList = res.data.data
          this.get_statistics_map()
        }
      })
    },
    // 获取设备类型列表
    getDeviceTypeList(type, pid) { // 1:类型 2:名称 3型号
      this.$http.post('device-type/list', {
        type,
        pid
      }).then(res => {
        if (res.data.code === 2000) {
          if (type == 1) {
            this.DeviceTypeList1 = res.data.data
          }
          if (type == 2) {
            this.DeviceTypeList2 = res.data.data
          }
          if (type == 3) {
            this.DeviceTypeList3 = res.data.data
          }
        }
        console.log(res)
      })
    },
    // 读取设备统计数据
    async get_statistics_map() {
      console.log(this.formData)
      let res = await this.$http.post('/statistics/map', this.formData)
      console.log(res.data)
      if (res.data.code !== 2000) {
        return
      }
      this.list = res.data.data
      let xAxis = []
      let data = []
      for (const resKey in this.list) {
        console.log(resKey)
        xAxis.push(resKey)
      }
      for (let i = 0; i < 10; i++) {
        data[i] = {
          name: this.nameList[`medicamentName_${i + 1}`],
          type: 'bar',
          barGap: 0,
          label: labelOption,
          data: []
        }
        for (const resKey in this.list) {
          data[i].data.push(this.list[resKey][i + 1])
        }
      }
      console.log(xAxis, data)
      this.setOptions(xAxis, data)
    },
    // 初始化 echarts
    initCharts() {
      this.chart = echarts.init(document.getElementById('myChart'));
      // this.setOptions();
    },
    // 计算高度
    getListHeight() {
      let Html = document.getElementsByClassName('box-card')
      if (Html.length > 0) {
        this.$store.state.clientHeight = Html[0].clientHeight - 131 - 60

      }
    },
    setOptions(xAxis, data) {
      this.chart.setOption({
        color: ['#6b9cd4', '#deab8a', '#817936', '#444693', '#d71345', '#843900', '#bed742', '#121a2a', '#78cdd1', '#009ad6', '#145b7d', '#11264f', '#c99979', '#840228', '#7d5886', '#bb505d', '#0c212b', '#494e8f'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['Forest', 'Steppe', 'Desert', 'Wetland']
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: {
              show: true
            },
            dataView: {
              show: true,
              readOnly: false
            },
            magicType: {
              show: true,
              type: ['line', 'bar', 'stack', 'tiled']
            },
            restore: {
              show: true
            },
            saveAsImage: {
              show: true
            }
          }
        },
        xAxis: [{
          type: 'category',
          axisTick: {
            show: false
          },
          data: xAxis
        }],
        yAxis: [{
          type: 'value',
          axisLabel: {
            formatter: '{value} L'
          }
        }],
        series: data,
        /*
        series: [{
          name: '升',
          type: 'bar',
          barGap: 0,
          label: labelOption,
          data: data
        },
          // {
          // 	name: 'Steppe',
          // 	type: 'bar',
          // 	label: labelOption,
          // 	data: [220, 182, 191, 234, 290, 182, 191, 234, 290]
          // },
          // {
          // 	name: 'Desert',
          // 	type: 'bar',
          // 	label: labelOption,
          // 	data: [150, 232, 201, 154, 190, 232, 201, 154, 190]
          // },
          // {
          // 	name: 'test',
          // 	type: 'bar',
          // 	label: labelOption,
          // 	data: [150, 232, 201, 154, 190, 232, 201, 154, 190]
          // }
        ]*/
      })
    }
  },
  mounted() {
    this.initCharts()
    this.getListHeight()
    window.onresize = () => {
      return (() => {
        this.getListHeight()
      })();
    }
  },
  destroyed() {
    window.onresize = null;
  }
};
</script>

<style lang="less" scoped>
.el-input {
  width: 240px;
  margin-right: 10px;
}

.el-select {
  width: 180px;
  margin-left: 20px;
}

.time {
  margin-left: 20px;
  width: 260px;
}

.container {
  display: flex;
  flex-direction: column;
}

.top {
  // height: 32px;
  margin-bottom: 20px;
  position: relative;
}

.add-username {
  position: absolute;
  right: 0;
}

.footer {
  display: flex;
  padding: 0;
  margin-top: 14px;
  justify-content: flex-end;
}

.state-item {
  width: 400px;
  height: 350px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 14px;
  border: 1px solid #BBBBBB;
  // justify-content: flex-start;
  // align-items: flex-start;

  .item-title {

    width: 100%;
    height: 54px;
    background-color: #E1F0F9;
    color: #000000;
    font-size: 16px;
    font-family: SourceHanSansSC-bold;
    text-align: center;
    line-height: 54px;

  }

  .item-1 {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    text-align: center;
  }

  .item-3 {
    width: 33.333%;
    height: 40px;
    text-align: center;
    word-wrap: break-word;
    // line-height: 40px;
  }

  .item-2 {
    box-sizing: border-box;
    width: 50%;
    height: 34px;
    text-align: center;
    line-height: 34px;
    border: 1px solid rgba(240, 240, 240, 100);
  }
}
</style>
